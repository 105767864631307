import React, { Component } from "react";
import Card from "react-md/lib/Cards/Card";
import CardText from "react-md/lib/Cards/CardText";
import CardTitle from "react-md/lib/Cards/CardTitle";
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import Media from "react-md/lib/Media/Media";
import MediaOverlay from "react-md/lib/Media/MediaOverlay";
import Divider from "react-md/lib/Dividers/Divider";
import UserLinks from "../UserLinks";
import config from "../../../data/SiteConfig";
import "./TitleSearch.scss";

class TitleSearch extends Component {
  render() {
    return (
      <div className="md-grid mobile-fix">
        <Grid className="wrapper">
          <Cell size={3}></Cell>
          <Cell size={6}>
            <h2 className="md-headline">Conducting a title search requires highly specialized skills and proficiency in working with the FAA record-keeping system.</h2>
            <p>
              The FAA Aircraft Registry in Oklahoma City serves many purposes. In addition to assigning N-Numbers to aircraft and issuing registration certificates, the Registry maintains a complex and extensive record-keeping system for the recording of ownership and security conveyances. Aircraft ownership history, as well as the existence of recorded liens against an aircraft, can be determined through careful examination of the bills of sale, security instruments, lien releases, and other documents appearing in the FAA records.
              <br /><br />
              The <b>Title Search</b> is the first step to making sure the FAA record for the aircraft you are buying, or financing, is free and clear of liens, encumbrances, and discrepancies that could compromise your interest. Title Search Reports are available for any aircraft currently or previously registered in the U.S. Reports are also available for turbine-powered or piston-powered aircraft engines having at least 550 rated take-off shaft horsepower, for jet-propulsion engines having at least 1,750 pounds of thrust, and for propellers capable of absorbing at least 750 take-off horsepower.​
            </p>
          </Cell>
          <Cell size={3}></Cell>
        </Grid>
        <Card className="md-grid md-cell--8">
          <div className="centered">
            <CardText>
              <p className="md-body-1 centered">
                We can help you determine if you have a Cape Town Transaction.  If you do, we offer all the support you need to navigate this complex area and protect the priority of your financial interest.
              </p>
            </CardText>
          </div>
        </Card>
      </div>
    );
  }
}

export default TitleSearch;
